import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"width":420,"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c(VList,[_c(VListItem,[_c(VListItemTitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("projectMenu.projects"))+" ")])],1)],1),_c(VDivider),(!_vm.projectsLoaded)?_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VProgressCircular,{staticClass:"mt-6",attrs:{"indeterminate":"","size":40,"color":"primary"}})],1)],1):_c('div',_vm._l((_vm.projects),function(project,key){return _c('project-item',{key:key,attrs:{"project":project}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }