import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ma-5",attrs:{"elevation":"2"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(" mdi-layers ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.project.project_date))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.project.name))])],1)],1),_c(VCardText,{staticClass:"py-0"},[(!_vm.productsLoaded)?_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VProgressCircular,{staticClass:"mt-6",attrs:{"indeterminate":"","size":20,"color":"primary"}})],1)],1):_vm._e(),_c('products-tree',{attrs:{"project-id":_vm.project.id,"products":_vm.products}})],1),_c(VCardActions,[_c(VSpacer),(_vm.project.report_download_url)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","right":"","href":_vm.project.report_download_url}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-file-document-outline")])],1)]}}],null,false,2565681875)},[_c('span',[_vm._v(_vm._s(_vm.$t("projectMenu.reportTooltip")))])]):_vm._e(),(_vm.isAdmin)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","right":""},on:{"click":function($event){_vm.showDeleteDialog = !_vm.showDeleteDialog}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-delete-forever-outline")])],1),_c('delete-project-dialog',{attrs:{"project-id":_vm.project.id,"project-name":_vm.project.name},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})]}}],null,false,1130332050)},[_c('span',[_vm._v(_vm._s(_vm.$t("projectMenu.deleteTooltip")))])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }