import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VList,[_c(VSubheader,{domProps:{"textContent":_vm._s(_vm.displayCoordinates)}}),_vm._l((_vm.features),function(properties,index){return [_c(VListItem,{key:properties.id},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-layers")])],1),_c(VListItemContent,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VListItemTitle,_vm._g(_vm._b({},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(properties.product.project.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(properties.product.project.name))])]),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.displayProductAndCountry(properties))}})],1),_c(VListItemAction,[_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.showProductDetails(
                properties.product.project.id,
                properties.product.id,
                properties.id
              )}}},[_vm._v("Details")])],1)],1),(index < properties.length - 1)?_c(VDivider,{key:`${index}-divider`,staticClass:"ml-4 mr-4"}):_vm._e()]})],2),(_vm.hasMultiplePages)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,[_c(VPagination,{attrs:{"length":_vm.pages,"total-visible":_vm.totalPerPage,"circle":""},on:{"input":_vm.getFeatures},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }