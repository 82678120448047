<template>
  <v-app-bar
    clipped-left
    clipped-right
    app
    color="white"
    extension-height="auto"
  >
    <v-app-bar-nav-icon @click="showTheMenu"></v-app-bar-nav-icon>
    <v-toolbar-title>eoArchive</v-toolbar-title>
    <v-btn-toggle borderless>
      <v-btn text @click="extended = !extended" class="ml-8">
        <v-icon>mdi-filter-cog-outline</v-icon>
        <span v-if="$vuetify.breakpoint.lgAndUp">
          {{ $t("bar.filterProjects") }}
        </span>
        <v-badge
          v-if="getNumberOfActiveFilters() > 0"
          color="accent"
          class="pr-2"
          :content="getNumberOfActiveFilters()"
        ></v-badge>
      </v-btn>
    </v-btn-toggle>
    <v-spacer></v-spacer>
    <profile-menu />
    <v-img
      contain
      max-width="100px"
      height="50px"
      :src="require('@/assets/logo.jpg')"
      alt="EOMAP Logo"
    ></v-img>
    <template v-slot:extension v-if="extended">
      <project-filter></project-filter>
    </template>
  </v-app-bar>
</template>

<script>
import ProfileMenu from "@/components/bar/CoreBarProfile";
import ProjectFilter from "@/components/bar/filter/ProjectFilter";
import { mapGetters, mapState } from "vuex";

export default {
  name: "TheBar",
  components: {
    ProfileMenu,
    ProjectFilter,
  },
  data: () => ({
    extended: false,
    numberActiveFilters: 3,
  }),
  computed: {
    ...mapState("app", ["showMenu"]),
  },
  methods: {
    ...mapGetters("app", ["getNumberOfActiveFilters"]),
    showTheMenu() {
      this.$store.dispatch("app/showMenu", !this.showMenu);
    },
  },
};
</script>

<style>
div.v-toolbar__extension {
  background-color: white !important;
}
</style>
