<template>
  <v-container>
    <v-row class="pr-0">
      <v-col>
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              :value="startDate"
              :label="$t(`dateFilter.${filterParameter}.startDate`)"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              prepend-icon="mdi-calendar"
              @click:clear="startDateCleared"
            />
          </template>
          <v-date-picker
            :value="startDate"
            @input="setStartDate"
            :min="minDate"
            :max="maxDate"
            ref="startPicker"
          />
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              :value="endDate"
              :label="$t(`dateFilter.${filterParameter}.endDate`)"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              prepend-icon="mdi-calendar"
              @click:clear="endDateCleared"
            />
          </template>
          <v-date-picker
            :value="endDate"
            @input="setEndDate"
            :min="startDate"
            :max="maxDate"
            ref="endPicker"
          />
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
export default {
  name: "DateFilter",
  props: {
    table: {
      type: String,
      required: true,
    },
    column: {
      type: String,
      required: true,
    },
    filterParameter: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dates: [],
    startDate: null,
    endDate: null,
    startDateMenu: false,
    endDateMenu: false,
    minDate: null,
    maxDate: null,
  }),
  computed: {
    ...mapState("app", ["projectsFilter"]),
  },
  methods: {
    ...mapActions("app", ["setProjectsFilter"]),
    setStartDate(date) {
      this.startDateMenu = false;
      this.startDate = date;
      if (this.startDate === this.minDate) {
        this.setProjectsFilter({
          prop: `${this.filterParameter}_min`,
          value: null,
        });
      } else {
        this.setProjectsFilter({
          prop: `${this.filterParameter}_min`,
          value: this.startDate,
        });
      }
    },
    setEndDate(date) {
      this.endDateMenu = false;
      this.endDate = date;
      if (this.endDate === this.maxDate) {
        this.setProjectsFilter({
          prop: `${this.filterParameter}_max`,
          value: null,
        });
      } else {
        this.setProjectsFilter({
          prop: `${this.filterParameter}_max`,
          value: this.endDate,
        });
      }
    },
    async getDates() {
      const response = await axios.get(`/${this.table}/choices/${this.column}`);
      const dates = await response.data.values;
      this.dates = dates.map((d) => new Date(d));

      this.minDate = new Date(Math.min(...this.dates))
        .toISOString()
        .slice(0, 10);

      this.maxDate = new Date(Math.max(...this.dates))
        .toISOString()
        .slice(0, 10);

      if (!this.endDate) {
        this.endDate = this.maxDate;
      }
      if (!this.startDate) {
        this.startDate = this.minDate;
      }
    },
    endDateCleared() {
      this.setEndDate(this.maxDate);
    },
    startDateCleared() {
      this.setStartDate(this.minDate);
    },
  },
  mounted() {
    this.startDate = this.projectsFilter[`${this.filterParameter}_min`];
    this.endDate = this.projectsFilter[`${this.filterParameter}_max`];
    this.getDates();
  },
  watch: {},
};
</script>

<style scoped></style>
