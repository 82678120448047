<template>
  <v-card>
    <!--    <v-btn class="fixed-top" fab x-small top right icon @click="closePopUp">-->
    <!--      <v-icon>mdi-close</v-icon>-->
    <!--    </v-btn>-->
    <v-list>
      <v-subheader v-text="displayCoordinates"></v-subheader>
      <template v-for="(properties, index) in features">
        <v-list-item :key="properties.id">
          <v-list-item-avatar><v-icon>mdi-layers</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title v-bind="attrs" v-on="on">
                  {{ properties.product.project.name }}
                </v-list-item-title>
              </template>
              <span>{{ properties.product.project.name }}</span>
            </v-tooltip>

            <v-list-item-subtitle
              v-text="displayProductAndCountry(properties)"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              text
              color="primary"
              @click="
                showProductDetails(
                  properties.product.project.id,
                  properties.product.id,
                  properties.id
                )
              "
              >Details</v-btn
            >
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="index < properties.length - 1"
          :key="`${index}-divider`"
          class="ml-4 mr-4"
        ></v-divider>
      </template>
    </v-list>

    <v-row justify="center" v-if="hasMultiplePages">
      <v-col>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="totalPerPage"
          circle
          @input="getFeatures"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "PopupContent",
  props: {
    lngLat: {
      type: Object,
      required: true,
    },
    featureProperties: {
      type: Array[String],
      required: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      totalPerPage: 5,
      features: [],
    };
  },

  computed: {
    hasMultiplePages() {
      return this.featureProperties.length > 5;
    },
    pages() {
      return Math.ceil(this.featureProperties.length / this.totalPerPage);
    },
    displayCoordinates() {
      return `${this.$t("popupContent.coordinates")} ${this.lngLat.lng.toFixed(
        4
      )} ${this.lngLat.lat.toFixed(4)}`;
    },
  },
  methods: {
    displayProductAndCountry(properties) {
      if (properties?.custom_name === null) {
        return `${properties.product.name} (${properties.region_code}) - ${properties.custom_name}`;
      } else {
        return `${properties.product.name} (${properties.region_code}) - ${
          properties.end_date.split(" ")[0]
        }`;
      }
    },
    showProductDetails(projectId, productId, productItemId) {
      this.$router.push({
        name: "SingleProductItemDetails",
        params: {
          projectId: projectId,
          productId: productId,
          productItemId: productItemId,
        },
      });
    },
    getFeatures() {
      if (this.currentPage === "undefined") {
        this.currentPage = 1;
      }
      const endOfSlice =
        this.featureProperties.length >= this.totalPerPage * this.currentPage
          ? this.totalPerPage * this.currentPage
          : this.featureProperties.length;
      this.features = this.featureProperties.slice(
        (this.currentPage - 1) * this.totalPerPage,
        endOfSlice
      );
    },
  },
  mounted() {
    this.getFeatures();
  },
};
</script>

<style scoped>
.fixed-top {
  position: fixed;
  top: 2px;
  right: 24px;
}
</style>
