<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("deleteProjectDialog.cardTitle") }}
      </v-card-title>
      <v-card-subtitle class="mt-1">{{ projectName }}</v-card-subtitle>
      <v-card-text>
        {{ $t("deleteProjectDialog.cardText") }}
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-model="isChecked"
          :label="$t('deleteProjectDialog.labelText')"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">
          {{ $t("deleteProjectDialog.cancelBtn") }}
        </v-btn>
        <v-btn :disabled="!isChecked" color="red" text @click="deleteProject">
          {{ $t("deleteProjectDialog.deleteBtn") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DeleteProjectDialog",
  data: () => ({
    isChecked: false,
  }),
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async deleteProject() {
      const url = `/delete-project/${this.projectId}`;
      try {
        const response = await axios.get(url);
        const data = response.data;
        console.log(data);
      } catch (err) {
        console.log(err);
      }
      console.log(this.projectId);
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
