import Vue from "vue";
import axios from "axios";

export default async function tokenInterceptor() {
  axios.interceptors.request.use(
    async (config) => {
      if (Vue.prototype.$keycloak.authenticated) {
        await Vue.prototype.$keycloak.keycloak.updateToken(70);
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
