<template>
  <v-container fluid>
    <v-progress-linear
      indeterminate
      color="primary"
      :active="!isLoaded"
    ></v-progress-linear>
    <v-row>
      <v-col>
        <v-btn text @click="closeWindow" color="primary">
          <v-icon>mdi-map</v-icon>
          Map
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-2">
      <v-col cols="5">
        <h3 v-if="productDetails?.custom_name">
          {{ productDetails?.custom_name }}
        </h3>
        <h3>{{ shortProductName }}</h3>
        <h4 style="color: gray">{{ project?.name }}</h4>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <tbody>
              <tr v-for="item in overviewItems" :key="item.name">
                <td>{{ item?.label }}</td>
                <td>{{ item?.value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row class="mt-5">
          <v-col>
            <v-btn color="primary" :href="productDetails?.product_download_url">
              <v-icon>mdi-download</v-icon>
              {{ $t("productDetails.download") }}
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col>
            <v-dialog v-model="abstractDialog" width="800">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" text v-bind="attrs" v-on="on">
                  Read Abstract
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Abstract
                </v-card-title>
                <v-card-text class="mt-3">
                  {{ productDetails?.abstract }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="abstractDialog = false">
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="7">
        <v-card height="600">
          <details-map
            v-if="productDetails"
            :product-details="productDetails"
          ></details-map>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import { shortProductFull, iso3ToFull } from "@/services/eomap.services";
import DetailsMap from "@/components/map/DetailsMap.vue";

export default {
  name: "SinglePoductItemDetails",
  components: { DetailsMap },
  data: () => ({
    productDetails: null,
    project: null,
    product: null,
    abstractDialog: false,
    map: null,
  }),
  computed: {
    shortProductName() {
      return shortProductFull[this.product?.name];
    },
    overviewItems() {
      return [
        {
          label: this.$t("productDetails.items.created"),
          value: this.productDetails?.date_of_creation,
        },
        {
          label: this.$t("productDetails.items.product"),
          value: this.product?.name,
        },
        {
          label: this.$t("productDetails.items.country"),
          value: iso3ToFull[this.productDetails?.region_code],
        },
        {
          label: this.$t("productDetails.items.sensor"),
          value: this.productDetails?.sensor,
        },
        {
          label: this.$t("productDetails.items.spatialResolution"),
          value: parseFloat(this.productDetails?.pixel_resolution).toFixed(1),
        },

        {
          label: this.$t("productDetails.items.temporalResolution"),
          value: this.formatTemporalResolution(
            this.productDetails?.start_date,
            this.productDetails?.end_date
          ),
        },
        {
          label: this.$t("productDetails.items.epsgCode"),
          value: this.productDetails?.epsg,
        },
      ];
    },
    isLoaded() {
      return this.product && this.productDetails && this.project;
    },
  },
  methods: {
    ...mapActions("app", ["setSelectedProductItemId"]),
    formatTemporalResolution(startDate, endDate) {
      if (startDate === endDate) {
        return startDate;
      }
      return `${startDate.split("T")[0]} - ${endDate.split("T")[0]}`;
    },
    displayValue(value) {
      return value ? value : "N/A";
    },
    closeWindow() {
      this.$router.push({ name: "Home" });
      this.setSelectedProductItemId(null);
    },
    async fetchProductDetailsById(productItemId) {
      const response = await axios.get(`/product-item/${productItemId}`);
      this.productDetails = response.data;
      return response.data;
    },
    async fetchProjectById(projectId) {
      const response = await axios.get(`/project/${projectId}`);
      this.project = response.data;
    },
    async fetchProductById(productId) {
      const response = await axios.get(`/product/${productId}`);
      this.product = response.data;
    },
  },
  mounted() {
    const productItemId = this.$route.params.productItemId;
    this.setSelectedProductItemId(parseInt(productItemId));
    this.fetchProductDetailsById(productItemId);
    this.fetchProjectById(this.$route.params.projectId);
    this.fetchProductById(this.$route.params.productId);
  },
};
</script>

<style scoped>
.abstract pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
</style>
