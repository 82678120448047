import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{attrs:{"clipped-left":"","clipped-right":"","app":"","color":"white","extension-height":"auto"},scopedSlots:_vm._u([(_vm.extended)?{key:"extension",fn:function(){return [_c('project-filter')]},proxy:true}:null],null,true)},[_c(VAppBarNavIcon,{on:{"click":_vm.showTheMenu}}),_c(VToolbarTitle,[_vm._v("eoArchive")]),_c(VBtnToggle,{attrs:{"borderless":""}},[_c(VBtn,{staticClass:"ml-8",attrs:{"text":""},on:{"click":function($event){_vm.extended = !_vm.extended}}},[_c(VIcon,[_vm._v("mdi-filter-cog-outline")]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("bar.filterProjects"))+" ")]):_vm._e(),(_vm.getNumberOfActiveFilters() > 0)?_c(VBadge,{staticClass:"pr-2",attrs:{"color":"accent","content":_vm.getNumberOfActiveFilters()}}):_vm._e()],1)],1),_c(VSpacer),_c('profile-menu'),_c(VImg,{attrs:{"contain":"","max-width":"100px","height":"50px","src":require('@/assets/logo.jpg'),"alt":"EOMAP Logo"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }