<template>
  <v-footer app color="white" inset padless height="25" style="font-size: 12px">
    <div class="pl-1">
      EOMAP GmbH & Co. KG | Schlosshof 4a | D-82229 Seefeld |
      <a
        href="mailto:info@eomap.de"
        class="px-1"
        style="cursor: pointer; text-decoration: underline"
      >
        info@eomap.de </a
      >|
      <a
        id="legal_notice"
        class="px-1"
        style="cursor: pointer; text-decoration: underline"
        href="https://www.eomap.com/legal-information/"
      >
        Legal Notice
      </a>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped></style>
