<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="2" v-if="isAdmin">
        <filter-combobox
          table="projects"
          column="access_role"
          filter-parameter="access_role"
        >
        </filter-combobox>
      </v-col>
      <v-col cols="12" sm="2">
        <filter-combobox
          table="projects"
          column="name"
          filter-parameter="project_name"
        >
        </filter-combobox>
      </v-col>
      <v-col cols="12" sm="2">
        <filter-combobox
          table="products"
          column="name"
          filter-parameter="product_name"
        >
        </filter-combobox>
      </v-col>
      <v-col cols="12" sm="2">
        <filter-combobox
          table="product-items"
          column="region_code"
          filter-parameter="region_code"
        ></filter-combobox>
      </v-col>
      <v-col class="pa-0" cols="12" sm="3">
        <date-filter
          table="projects"
          column="project_date"
          filter-parameter="project_date"
        ></date-filter>
      </v-col>
      <!-- Scene date -->
      <v-col class="pa-0" cols="12" sm="3">
        <date-filter
          table="product-items"
          column="end_date"
          filter-parameter="scene_end_date"
        ></date-filter>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterCombobox from "@/components/bar/filter/FilterCombobox";
import { mapActions, mapGetters, mapState } from "vuex";
import DateFilter from "@/components/bar/filter/DateFilter";

export default {
  name: "ProjectFilter",
  components: {
    FilterCombobox,
    DateFilter,
  },
  computed: {
    ...mapState("app", ["projectsFilter"]),
    filter() {
      return this.getFilter();
    },
    isAdmin() {
      return this.$keycloak.tokenParsed.resource_access.eoarchive.roles.includes(
        "admin"
      );
    },
  },
  methods: {
    ...mapActions("projects", ["fetchProjectsGeojson", "fetchProjects"]),
    ...mapGetters("app", ["getFilter"]),
  },
  watch: {
    filter: {
      handler() {
        this.fetchProjects(this.filter);
        this.fetchProjectsGeojson(this.filter);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
