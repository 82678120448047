import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/localization/i18n";
import vuetify from "./plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import tokenInterceptor from "./services/interceptors";
import axios from "axios";
import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;
axios.defaults.baseURL = "https://archive.api.eomap.com";
// axios.defaults.baseURL = "http://127.0.0.1:8000";

Vue.use(VueKeyCloak, {
  config: {
    url: "https://auth.eomap.com/auth",
    realm: "eomap",
    clientId: "eoarchive",
    logoutRedirectUri: window.location.origin,
  },
  onReady: () => {
    tokenInterceptor();
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  },
  onInitError: (error) => {
    console.log(error);
  },
});
