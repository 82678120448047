<template>
  <v-card class="ma-5" elevation="2">
    <v-list-item two-line>
      <v-list-item-avatar>
        <v-icon> mdi-layers </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ project.project_date }}</v-list-item-title>
        <v-list-item-subtitle>{{ project.name }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text class="py-0">
      <v-layout align-center justify-center column v-if="!productsLoaded">
        <v-flex row align-center>
          <v-progress-circular
            indeterminate
            :size="20"
            color="primary"
            class="mt-6"
          ></v-progress-circular>
        </v-flex>
      </v-layout>
      <products-tree :project-id="project.id" :products="products">
      </products-tree>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip top v-if="project.report_download_url">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            right
            v-bind="attrs"
            v-on="on"
            :href="project.report_download_url"
          >
            <v-icon right>mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("projectMenu.reportTooltip") }}</span>
      </v-tooltip>
      <v-tooltip top v-if="isAdmin">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            right
            v-bind="attrs"
            v-on="on"
            @click="showDeleteDialog = !showDeleteDialog"
          >
            <v-icon color="red">mdi-delete-forever-outline</v-icon>
          </v-btn>
          <delete-project-dialog
            v-model="showDeleteDialog"
            :project-id="project.id"
            :project-name="project.name"
          ></delete-project-dialog>
        </template>
        <span>{{ $t("projectMenu.deleteTooltip") }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import ProductsTree from "@/components/menu/ProductsTree.vue";
import DeleteProjectDialog from "./DeleteProjectDialog.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "ProjectItem",
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  components: { ProductsTree, DeleteProjectDialog },
  data: () => ({
    productsLoaded: true,
    showDeleteDialog: false,
  }),
  computed: {
    ...mapState("app", ["selectedProductItemId"]),
    isAdmin() {
      return (
        // TODO: delete role only checked in frontend
        this.$keycloak.tokenParsed.resource_access.eoarchive.roles.includes(
          "delete"
        ) &
        this.$keycloak.tokenParsed.resource_access.eoarchive.roles.includes(
          "admin"
        )
      );
    },

    products() {
      return this.project.products;
    },
  },
  methods: {
    ...mapActions("app", ["setSelectedProductId"]),
  },
};
</script>

<style scoped></style>
