import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-container"},[_c('div',{staticStyle:{"z-index":"1"},attrs:{"id":"map"}}),_c('div',{staticClass:"map-btn-group"},[(_vm.showLayerMenu)?_c(VCard,{staticClass:"menu-card",attrs:{"width":"300"}},[_c(VCardText,[_c(VSelect,{staticClass:"mt-5",attrs:{"label":"Colormap","items":_vm.cmItems,"return-object":"","item-text":"name","item-value":"key","dense":""},on:{"change":_vm.updateRastlessLayer},model:{value:(_vm.cmSelected),callback:function ($$v) {_vm.cmSelected=$$v},expression:"cmSelected"}}),_c(VRangeSlider,{staticClass:"mt-8",attrs:{"label":"Range","min":_vm.minMaxRange[0],"max":_vm.minMaxRange[1],"step":0.1,"dense":"","thumb-label":"always"},on:{"end":_vm.updateRastlessLayer},model:{value:(_vm.layerRange),callback:function ($$v) {_vm.layerRange=$$v},expression:"layerRange"}}),_c(VCheckbox,{attrs:{"dense":"","label":"Logarithmic scale","disabled":!_vm.allowLog},on:{"change":_vm.updateRastlessLayer},model:{value:(_vm.log),callback:function ($$v) {_vm.log=$$v},expression:"log"}})],1)],1):_vm._e(),_c(VBtn,{staticClass:"btn-conf",attrs:{"size":"small","disabled":!_vm.singleBandLayer},on:{"click":function($event){_vm.showLayerMenu = !_vm.showLayerMenu}}},[_c(VIcon,{attrs:{"size":"28"}},[_vm._v("mdi-layers")])],1)],1),(_vm.singleBandLayer)?_c(VImg,{staticClass:"legend-img",attrs:{"src":_vm.legendImageSrc,"width":"300"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }