import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("deleteProjectDialog.cardTitle"))+" ")]),_c(VCardSubtitle,{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.projectName))]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("deleteProjectDialog.cardText"))+" ")]),_c(VCardActions,[_c(VCheckbox,{attrs:{"label":_vm.$t('deleteProjectDialog.labelText')},model:{value:(_vm.isChecked),callback:function ($$v) {_vm.isChecked=$$v},expression:"isChecked"}}),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" "+_vm._s(_vm.$t("deleteProjectDialog.cancelBtn"))+" ")]),_c(VBtn,{attrs:{"disabled":!_vm.isChecked,"color":"red","text":""},on:{"click":_vm.deleteProject}},[_vm._v(" "+_vm._s(_vm.$t("deleteProjectDialog.deleteBtn"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }