<template>
  <v-navigation-drawer
    :width="420"
    v-model="showMenu"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-list>
      <v-list-item>
        <v-list-item-title class="text-h6">
          {{ $t("projectMenu.projects") }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-layout align-center justify-center column v-if="!projectsLoaded">
      <v-flex row align-center>
        <v-progress-circular
          indeterminate
          :size="40"
          color="primary"
          class="mt-6"
        ></v-progress-circular>
      </v-flex>
    </v-layout>
    <div v-else>
      <project-item
        v-for="(project, key) in projects"
        :key="key"
        :project="project"
      >
      </project-item>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProjectItem from "./ProjectItem";

export default {
  name: "ProjectMenu",
  components: {
    ProjectItem,
  },
  computed: {
    ...mapState("projects", [
      "projects",
      "projectsLoaded",
      "projectsGeojson",
      "projectsGeojsonLoaded",
    ]),
    ...mapState("app", ["projectsFilter"]),
    ...mapState("projects", ["projects"]),
    showMenu: {
      get() {
        return this.$store.state.app.showMenu;
      },
      set(val) {
        this.$store.commit("app/SHOW_MENU", val);
      },
    },
  },
  methods: {
    ...mapActions("projects", ["fetchProjectsGeojson", "fetchProjects"]),
  },
  mounted() {
    this.fetchProjects();
    this.fetchProjectsGeojson();
  },
};
</script>

<style scoped></style>
