import Vue from "vue";
import VueRouter from "vue-router";
import ProjectMenu from "@/components/menu/ProjectMenu";
import MainMap from "@/components/map/MainMap";
import SinglePoductItemDetails from "@/components/SingleProductItemDetails";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      menu: ProjectMenu,
      content: MainMap,
    },
  },
  {
    path: "/project/:projectId/product/:productId/product-item/:productItemId",
    name: "SingleProductItemDetails",
    components: {
      menu: ProjectMenu,
      content: SinglePoductItemDetails,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
