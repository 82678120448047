import axios from "axios";

const state = {
  projectsGeojson: "",
  projectsGeojsonLoaded: false,
  zoomAoi: null,
  projects: null,
  projectsLoaded: false,
  products: null,
  productsLoaded: false,
};

const actions = {
  async fetchProjects({ commit }, filterParams = {}) {
    commit("PROJECTS_LOADED", false);
    try {
      const response = await axios.get("/projects", { params: filterParams });
      commit("SET_PROJECTS", response.data);
    } catch (err) {
      console.log(err);
    }
    commit("PROJECTS_LOADED", true);
  },

  async fetchProjectsGeojson({ commit }, filterParams = {}) {
    commit("PROJECTS_GEOJSON_LOADED", false);
    try {
      const response = await axios.get("/products-geojson", {
        params: filterParams,
      });
      commit("SET_PROJECTS_GEOJSON", response.data);
    } catch (err) {
      console.log(err);
    }
    commit("PROJECTS_GEOJSON_LOADED", true);
  },
  setZoomAoi({ commit }, value) {
    commit("SET_ZOOM_AOI", value);
  },
};

const mutations = {
  SET_PROJECTS_GEOJSON(state, data) {
    state.projectsGeojson = data;
  },
  PROJECTS_GEOJSON_LOADED(state, value) {
    state.projectsGeojsonLoaded = value;
  },
  SET_ZOOM_AOI(state, value) {
    state.zoomAoi = value;
  },
  SET_PROJECTS(state, data) {
    state.projects = data;
  },
  PROJECTS_LOADED(sate, value) {
    state.projectsLoaded = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
