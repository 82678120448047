<template>
  <v-treeview
    activatable
    :items="treeProductsArray"
    open-on-click
    transition
    :load-children="fetchProductItems"
    color="primary"
    return-object
    @update:active="openMetadata"
  >
    <template v-slot:append="{ item }">
      <v-btn
        icon
        v-if="!item.children"
        @click.stop="zoomToProductAoi(item.extent)"
      >
        <v-icon>mdi-magnify-expand</v-icon>
      </v-btn>
    </template>
  </v-treeview>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { shortProductFull } from "@/services/eomap.services";

export default {
  name: "ProductsTree",
  props: {
    products: {
      type: Array,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      treeProducts: Object.assign(
        {},
        ...this.products.map((product) => ({
          [product.id]: {
            id: product.id,
            name: shortProductFull[product.name],
            children: [],
          },
        }))
      ),
    };
  },
  computed: {
    treeProductsArray() {
      return Object.values(this.treeProducts);
    },
  },
  methods: {
    ...mapActions("projects", ["setZoomAoi"]),
    dateFormat(start, end) {
      let format;
      if (end) {
        format = `${start.split("T")[0].substring(0, 4)} - ${end
          .split("T")[0]
          .substring(0, 4)}`;
      } else {
        format = start.split("T")[0];
      }

      return format;
    },
    fetchProductItems(item) {
      const url = `/product-items/${item.id}`;

      return axios.get(url).then((response) => {
        let productItems = response.data;
        productItems.forEach((item) => {
          if (item.custom_name) {
            item.name = item.custom_name;
          } else {
            item.name = this.dateFormat(item.start_date, item.end_date);
          }
        });
        this.treeProducts[item.id].children = productItems;
      });
    },
    zoomToProductAoi(geojsonAoi) {
      this.setZoomAoi(geojsonAoi);
    },
    openMetadata(items) {
      if (items.length) {
        const item = items[0];
        this.$router.push({
          name: "SingleProductItemDetails",
          params: {
            projectId: this.projectId,
            productId: item.product_id,
            productItemId: item.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped></style>
