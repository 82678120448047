// import axios from "axios";

const state = {
  userPofile: null,
  ui_language: "en",
  languages_dict: [
    { value: "de", text: "German" },
    { value: "en", text: "English" },
    { value: "it", text: "Italian" },
    { value: "pt", text: "Portuguese" },
    { value: "fr", text: "French" },
  ],
};

const actions = {};

const mutations = {
  SET_USER_PROFILE_LANGUAGE(state, ui_language) {
    state.ui_language = ui_language;
  },
  SET_USER_KEYCLOAK_PROFILE(state, profile) {
    state.userPofile = profile;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
