<template>
  <mgl-map
    container="map-test"
    :center.sync="center"
    :accessToken="accessToken"
    :mapStyle="mapStyle"
    @load="onMapLoaded"
    id="map"
  >
    <div id="popup-content"></div>

    <mgl-geojson-layer
      sourceId="projectsSourceId"
      :source="projectsSource"
      :layerId="projectsAreaLayerId"
      :layer="projectsAreaLayer"
    />
    <mgl-geojson-layer
      sourceId="projectsSourceId"
      :source="projectsSource"
      layerId="projectsOutlineLayerId"
      :layer="projectsOutlineLayer"
    />
  </mgl-map>
</template>

<script>
import Mapbox from "mapbox-gl";
import bbox from "@turf/bbox";
import { MglMap, MglGeojsonLayer } from "vue-mapbox";
import MapboxConfig from "@/plugins/mapbox";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapActions, mapState } from "vuex";
import PopupContent from "@/components/map/PopupContent";
import Vue from "vue";
import store from "@/store";
import i18n from "@/localization/i18n";
import vuetify from "@/plugins/vuetify";
import router from "@/router";

const popupClass = Vue.extend(PopupContent);

export default {
  name: "MainMap",
  components: {
    MglMap,
    MglGeojsonLayer,
  },
  data() {
    return {
      showCard: false,
      accessToken: MapboxConfig.accessToken,
      mapStyle: MapboxConfig.style,
      center: MapboxConfig.center,
      projectsAreaLayerId: "projectsAreaLayerId",
      projectsSource: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        generateId: true,
      },
      projectsAreaLayer: {
        type: "fill",
        paint: {
          "fill-color": "transparent",
          "fill-outline-color": "#0080ff",
        },
      },
      projectsOutlineLayer: {
        type: "line",
        paint: {
          "line-color": [
            "case",
            ["boolean", ["feature-state", "clicked"], false],
            "#ff9800",
            "#0080ff",
          ],
          "line-width": 3,
        },
      },
    };
  },
  computed: {
    ...mapState("projects", [
      "projectsGeojson",
      "projectsGeojsonLoaded",
      "zoomAoi",
    ]),
  },
  methods: {
    ...mapActions("projects", ["setZoomAoi"]),
    zoomTo(geojson) {
      const geojson_bbox = bbox(geojson);
      this.map.fitBounds(geojson_bbox, { padding: 5 });
    },
    onMapLoaded(event) {
      this.map = event.map;

      if (this.projectsGeojsonLoaded) {
        this.projectsSource.data = this.projectsGeojson;
        this.setZoomAoi(this.projectsSource.data);
      }
      let clickedPolygonIds = [];
      // remove highlight color of polygon
      this.map.on("click", () => {
        if (clickedPolygonIds.length !== 0) {
          clickedPolygonIds.forEach((id) => {
            this.map.removeFeatureState({
              source: "projectsSourceId",
              id: id,
            });
          });
        }
      });
      this.map.on("click", this.projectsAreaLayerId, (e) => {
        // highlight clicked Polygon
        clickedPolygonIds = e.features.map((feature) => feature.id);
        clickedPolygonIds.forEach((id) => {
          this.map.setFeatureState(
            { source: "projectsSourceId", id: id },
            { clicked: true }
          );
        });
        // Create Popup
        const popup = new Mapbox.Popup()
          .setLngLat(e.lngLat)
          .setHTML('<div id="popup-content"></div>')
          .addTo(this.map);
        const featureProperties = e.features.map((feature) => {
          let props = feature.properties;
          // product is a string after getting it from e.features
          props.product = JSON.parse(props.product);
          return props;
        });

        const popupInstance = new popupClass({
          store,
          i18n,
          vuetify,
          router,
          propsData: {
            featureProperties: featureProperties,
            lngLat: e.lngLat,
          },
        });
        popupInstance.$mount("#popup-content");
        popup._update();
      });
      // show pointer if mouse is in Polygon
      this.map.on("mouseenter", this.projectsAreaLayerId, () => {
        this.map.getCanvas().style.cursor = "pointer";
      });
      this.map.on("mouseleave", this.projectsAreaLayerId, () => {
        this.map.getCanvas().style.cursor = "";
      });
    },
  },
  watch: {
    projectsGeojson: {
      handler() {
        if (this.projectsGeojsonLoaded && this.map) {
          this.projectsSource.data = this.projectsGeojson;
          this.setZoomAoi(this.projectsSource.data);
        }
      },
      immediate: true,
    },
    zoomAoi: {
      handler() {
        if (this.zoomAoi) {
          this.zoomTo(this.zoomAoi);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.mapbox = Mapbox;
  },
  activated() {
    if (this.map) {
      this.map.resize();
    }
  },
};
</script>

<style>
#map {
  width: 100%;
  height: 100%;
}
.mapboxgl-popup-content {
  padding: 0 !important;
}
.mapboxgl-popup {
  max-width: 450px !important;
}
.mapboxgl-popup-close-button {
  display: none !important;
}
</style>
