<template>
  <v-combobox
    clearable
    :label="displayLabel"
    :items="choices"
    item-text="displayValue"
    item-value="value"
    v-model="value"
    @change="onChange"
  >
  </v-combobox>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import { choicesFullName } from "@/services/eomap.services";

export default {
  name: "FilterCombobox",
  props: {
    table: {
      type: String,
      required: true,
    },
    column: {
      type: String,
      required: true,
    },
    filterParameter: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    choices: [],
    value: null,
  }),
  computed: {
    ...mapState("app", ["projectsFilter"]),
    displayLabel() {
      return this.columnNameToText();
    },
  },

  methods: {
    ...mapActions("app", ["setProjectsFilter"]),
    async getChoices() {
      const response = await axios.get(`/${this.table}/choices/${this.column}`);
      const choices = await response.data.values;
      const choicesFull = choicesFullName[this.filterParameter];
      if (typeof choicesFull === "undefined") {
        this.choices = choices.map((choice) => {
          return { value: choice, displayValue: choice };
        });
      } else {
        this.choices = choices.map((choice) => {
          return {
            value: choice,
            displayValue: choicesFull[choice] ? choicesFull[choice] : choice,
          };
        });
        this.choices.sort((a, b) =>
          a.displayValue
            .toUpperCase()
            .localeCompare(b.displayValue.toUpperCase())
        );
        return choices;
      }
    },
    onChange(item) {
      let value = item;
      if (item) {
        value = item.value;
      }
      this.setProjectsFilter({ prop: this.filterParameter, value: value });
    },
    columnNameToText() {
      return this.$t(`filterCombobox.${this.filterParameter}`);
    },
  },
  mounted() {
    this.getChoices();
    this.value = this.projectsFilter[this.filterParameter];
  },
};
</script>

<style scoped></style>
