import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"left":"","open-on-hover":"","close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("bar.welcome"))+" "+_vm._s(_vm.$keycloak.tokenParsed.given_name)+" ")]):_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-account-check-outline")])],1)]}}])},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-account")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$keycloak.fullName))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$keycloak.idTokenParsed.email)+" ")])],1)],1),_c(VListItem,[_c(VListItemAction,[_c(VSelect,{attrs:{"attach":"","prepend-icon":"mdi-map","items":_vm.availableLanguagesDict,"label":_vm.$t('bar.selectedLanguage'),"dense":""},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"accent"},on:{"click":_vm.$keycloak.logoutFn}},[_vm._v(_vm._s(_vm.$t("bar.logout"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }