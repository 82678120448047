const state = {
  showMenu: null,
  snackDialog: false,
  snackColor: "success",
  snackMessage: "",
  snackTimeout: -1,
  selectedProductItemId: null,
  projectsFilter: {
    project_name: null,
    product_name: null,
    access_role: null,
    region_code: null,
    project_date_min: null,
    project_date_max: null,
    scene_end_date_min: null,
    scene_end_date_max: null,
  },
};

const getters = {
  getFilter() {
    let filterParams = {};
    Object.entries(state.projectsFilter).forEach(([key, value]) => {
      if (value) {
        filterParams[key] = value;
      }
    });
    return filterParams;
  },

  getNumberOfActiveFilters() {
    let count = 0;
    Object.entries(state.projectsFilter).forEach(([, obj]) => {
      if (obj) {
        count++;
      }
    });
    return count;
  },
};

const actions = {
  showMenu({ commit }, value) {
    commit("SHOW_MENU", value);
  },
  showSnackbar({ commit }, { show, message, color, timeout }) {
    commit("SHOW_SNACKBAR", { show, message, color, timeout });
  },
  hideSnackbar({ commit }) {
    commit("HIDE_SNACKBAR");
  },
  setSelectedProductItemId({ commit }, value) {
    commit("SET_SELECTED_PRODUCT_ITEM_ID", value);
  },
  setProjectsFilter({ commit }, { prop, value }) {
    commit("SET_PROJECT_FILTER_PROPERTY", { prop, value });
  },
};

const mutations = {
  SHOW_MENU(state, value) {
    state.showMenu = value;
  },
  SHOW_SNACKBAR(state, { show, message, color, timeout }) {
    state.snackMessage = message;
    state.snackColor = color;
    state.snackDialog = show;
    state.snackTimeout = timeout;
  },
  HIDE_SNACKBAR(state) {
    state.snackDialog = false;
  },
  SET_SELECTED_PRODUCT_ITEM_ID(state, value) {
    state.selectedProductItemId = value;
  },
  SET_PROJECT_FILTER_PROPERTY(sate, { prop, value }) {
    state.projectsFilter[prop] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
