<template>
  <div class="map-container">
    <div id="map" style="z-index: 1"></div>
    <div class="map-btn-group">
      <v-card class="menu-card" width="300" v-if="showLayerMenu">
        <v-card-text>
          <v-select
            class="mt-5"
            label="Colormap"
            v-model="cmSelected"
            :items="cmItems"
            return-object
            item-text="name"
            item-value="key"
            dense
            @change="updateRastlessLayer"
          ></v-select>
          <v-range-slider
            label="Range"
            class="mt-8"
            :min="minMaxRange[0]"
            :max="minMaxRange[1]"
            :step="0.1"
            v-model="layerRange"
            dense
            thumb-label="always"
            @end="updateRastlessLayer"
          >
          </v-range-slider>
          <v-checkbox
            v-model="log"
            dense
            label="Logarithmic scale"
            @change="updateRastlessLayer"
            :disabled="!allowLog"
          ></v-checkbox>
        </v-card-text>
      </v-card>
      <v-btn
        size="small"
        class="btn-conf"
        @click="showLayerMenu = !showLayerMenu"
        :disabled="!singleBandLayer"
      >
        <v-icon size="28">mdi-layers</v-icon>
      </v-btn>
    </div>
    <v-img
      :src="legendImageSrc"
      class="legend-img"
      width="300"
      v-if="singleBandLayer"
    ></v-img>
  </div>
</template>

<script>
import bbox from "@turf/bbox";
import L from "leaflet";
import axios from "axios";

export default {
  name: "DetailsMap",
  props: ["productDetails"],
  data: () => ({
    map: null,
    cmSelected: {
      name: "Deep",
      key: "deep_r",
    },
    cmItems: [
      {
        name: "Gray",
        key: "gray",
      },
      {
        name: "Deep",
        key: "deep_r",
      },
      {
        name: "Viridis",
        key: "viridis",
      },
      {
        name: "Haline",
        key: "haline",
      },
      {
        name: "Ice",
        key: "ice",
      },
      {
        name: "Thermal",
        key: "thermal",
      },
      {
        name: "Jet",
        key: "jet",
      },
    ],
    log: false,
    minMaxRange: [0, 10],
    layerRange: [0, 10],
    showLayerMenu: false,
    singleBandLayer: false,
    rastlessLayer: null,
  }),
  computed: {
    rastlessUrlSingleBand() {
      return `https://rastless.api.eomap.com/tiles/{z}/{x}/{y}.png?s3_token=${this.productDetails.s3_visualize}&cmap_name=${this.cmSelected.key}&cmap_log=${this.log}&cmap_min=${this.layerRange[0]}&cmap_max=${this.layerRange[1]}`;
    },
    rastlessUrlMultiBand() {
      return `https://rastless.api.eomap.com/tiles/{z}/{x}/{y}.png?s3_token=${this.productDetails.s3_visualize}&expr=b1;b2;b3`;
    },
    allowLog() {
      return this.minMaxRange[0] > 0 && this.minMaxRange[1] > 0;
    },
    legendImageSrc() {
      return `https://rastless.api.eomap.com/legend?cmap_name=${this.cmSelected.key}&cmap_log=${this.log}&cmap_min=${this.layerRange[0]}&cmap_max=${this.layerRange[1]}&orientation=horizontal`;
    },
  },
  methods: {
    initMap(extentPolygon) {
      let extent = bbox(extentPolygon);
      extent = [
        [extent[1], extent[0]],
        [extent[3], extent[2]],
      ];
      this.map = L.map("map", { zoomControl: false }).fitBounds(extent);
      L.control
        .zoom({
          position: "topright",
        })
        .addTo(this.map);

      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(this.map);
    },
    async fetchLayerStats(s3Token) {
      const url = `https://rastless.api.eomap.com/layer-statistic?s3_token=${s3Token}`;
      const response = await axios.get(url);

      return response.data;
    },
    isSingleBandLayer(layerStats) {
      return Object.keys(layerStats.data).length === 1;
    },
    updateRange(stats) {
      this.singleBandLayer = this.isSingleBandLayer(stats);
      if (this.singleBandLayer) {
        const minMaxRange = [stats.data.b1.min, stats.data.b1.max];
        this.minMaxRange = minMaxRange;
        this.layerRange = minMaxRange;
      }
    },
    addRastlessLayer() {
      const url = this.singleBandLayer
        ? this.rastlessUrlSingleBand
        : this.rastlessUrlMultiBand;
      this.rastlessLayer = L.tileLayer(url).addTo(this.map);
    },
    updateRastlessLayer() {
      this.rastlessLayer.setUrl(this.rastlessUrlSingleBand);
    },
  },
  mounted() {
    this.initMap(this.productDetails.extent);
  },
  created() {
    this.fetchLayerStats(this.productDetails.s3_visualize).then((stats) => {
      this.updateRange(stats);
      this.addRastlessLayer();
    });
  },
};
</script>

<style scoped>
.map-container {
  display: block;
  height: 100%;
  width: 100%;
}

#map {
  width: 100%;
  height: 100%;
  z-index: 0;
}
.map-btn-group {
  z-index: 2;
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}

.btn-conf {
  padding: 0;
  width: 36px;
  min-width: 36px !important;
}

.menu-card {
  margin-right: 8px;
}

.legend-img {
  z-index: 2;
  display: flex;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

>>> .leaflet-top.leaflet-right {
  margin-top: 48px;
}
</style>
