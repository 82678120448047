import Vue from "vue";
import Vuex from "vuex";
import app from "@/store/app.store";
import management from "@/store/management.store";
import projects from "@/store/projects.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    management,
    projects,
  },
});
