import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2c4186", // #E5393
        accent: colors.orange,
      },
      dark: {
        primary: "#2c4186",
      },
    },
  },
});
